import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

export const PlayButton = styled.button`
  position: relative;
  left: 0px;
  top: -200px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: 0.2s background-color ease;
  background-color: transparent;
  ${MEDIA.DESKTOP`
  top: -175px;
    `};
  :hover {
    background-color: #0088cc;
    cursor: pointer;
    i {
      transform: scale(1.2);
    }
  }
`;

export const Figure = styled.figure`
  color: #757575;
  * {
    box-sizing: border-box;
  }
  overflow: hidden;
  .fluidContainer {
    overflow: hidden;
    position: relative;
  }
  .podLink {
    box-sizing: border-box;
    text-decoration: none;
  }
  .fluidImage {
    transition: transform 0.25s ease-out;
  }
  .fluidStatus {
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    bottom: 0rem;
    padding: 1rem;
    color: #fff;
    display: none;
  }
  :hover {
    .fluidImage {
      transform: scale(1.2);
    }
  }

  &.podcastItem:hover {
    outline: 4px solid black;
    transform: translateY(-1px);
    -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 29px rgba(0, 0, 0, 0.3);
  }
  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
