import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Item from 'components/gallery/item';
import PodCastItem from 'components/gallery/item/podCastItem';
import { Container } from './gallery.css';

export const Gallery = ({ items, className }) => (
  <Container className={className}>
    {items.map((item, i) => (
      <Link
        key={i}
        className="podLink"
        to="/productions"
        activeStyle={{ color: 'red' }}
        partiallyActive={true}
      >
        <Item {...item} className="fluidGallery" />
      </Link>
    ))}
  </Container>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const Podcasts = ({ items, className }) => (
  <Container className={{ className }}>
    {items.map((item, i) => (
      <PodCastItem {...item} key={i} />
    ))}
  </Container>
);

Podcasts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
