import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { Title, Copy, Figure } from './item.css';

const Content = ({ title, copy, image, status }) => (
  <>
    <div className="fluidContainer">
      <div className="fluidStatus">{status}</div>
      <Img
        className="fluidImage"
        fluid={image ? image.childImageSharp.fluid : {}}
        alt={title}
      />
    </div>
    <figcaption>
      <Title>{title}</Title>
      <Copy>{copy} </Copy>
    </figcaption>
  </>
);

export const PodCastItem = ({ title, copy, image, link, status = null }) => (
  <Figure className="podcastItem">
    {link ? (
      <Link className="podLink" to={link} partiallyActive={true}>
        <Content title={title} copy={copy} image={image} status={status} />
      </Link>
    ) : (
      <Content title={title} copy={copy} image={image} status={status} />
    )}
  </Figure>
);

PodCastItem.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  link: PropTypes.string,
  status: PropTypes.string,
};

export default PodCastItem;
