import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Podcasts } from 'components/gallery';
import Box from 'components/box';
import Title from 'components/title';
import Head from 'components/head';

const Productions = ({ data }) => (
  <>
    <Box>
      <Title as="h2" size="large" dangeo>
        {data.productionsJson.content.childMarkdownRemark.rawMarkdownBody}
      </Title>
    </Box>
    <Head pageTitle={data.productionsJson.title} />
    <Box>
      <h2>{data.productionsJson.podCastHeader}</h2>
    </Box>
    <Podcasts items={data.productionsJson.podcasts} />
  </>
);

Productions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Productions;

export const query = graphql`
  query ProductionsQuery {
    productionsJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      podCastHeader
      podcasts {
        title
        slug
        copy
        imageSocial {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        status
        link
        episodes {
          title
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageSocial {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

              fixed(width: 500, height: 500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          releaseDate
          status
          URI
        }
      }
    }
  }
`;
